import request from '@/network/request';


/**
 * �����б�
 */
export async function GetCashes(data) {
    return request.post('/Cash/GetAllUserCashes', data);
}


/**
 * �������
 */
export async function AuditCash(data) {
    return request.post('/Cash/AuditCash', data);
}
