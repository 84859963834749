<template>
    <el-dialog :title="title"
               :visible.sync="dialogFormVisible"
               width="500px"
               @close="close">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="申请编号">
                <el-input v-model="form.code" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="用户手机">
                <el-input v-model="form.customerMobile" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="用户姓名">
                <el-input v-model="form.customerName" autocomplete="off" disabled></el-input>
            </el-form-item>

            <el-form-item label="提现金额">
                <el-input v-model="form.money" autocomplete="off" disabled></el-input>
            </el-form-item>



            <el-form-item label="是否通过" prop="isPass">
                <el-select v-model="form.isPass" placeholder="请选择" clearable filterable>
                    <el-option v-for="item in verifyStated"
                               :key="item.value"
                               :label="item.name"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="审核备注" prop="auditNote">
                <el-input v-model="form.auditNote" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>


        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    var enumData = require('@/config/data');
    import { AuditCash } from '@/api/financy';
    export default {
        name: 'VerifyCash',
        data() {
            return {
                verifyStated: enumData.verifyStated,
                form: {
                    id: 0,
                    code: '',
                    customerMobile: '',
                    customerName: '',
                    money: 0.00,
                    isPass: null,
                    auditNote: '',
                    userItype: null,
                },
                rules: {
                    isPass: [{ required: true, trigger: 'blur', message: '请选择审核状态' }],
                    auditNote: [{ required: true, trigger: 'blur', message: '请输入审核备注' }],
                },
                title: '',
                dialogFormVisible: false,
            }
        },
        methods: {
            showEdit(row) {
                this.title = '审核';
                this.form = Object.assign(this.form, row);
                this.dialogFormVisible = true
            },
            close() {
                this.$refs['form'].resetFields()
                this.form = this.$options.data().form
                this.dialogFormVisible = false
            },
            save() {
                console.log(this.form);
                this.$refs['form'].validate(async (valid) => {
                    if (!valid) return false;
                    if (this.form.id === 0) {
                        this.$baseMessage("编号无效", 'error');
                    }

                    const { isSucceed, message } = await AuditCash(this.form);
                    if (!isSucceed) return this.$baseMessage(message, 'error');
                    if (isSucceed) this.close();
                    this.$emit('fetch-data');
                })
            },
        },
    }
</script>
